import React from 'react';

import PageTitle from 'src/components/PageTitle';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider
} from '@mui/material';

import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

const BioGuide: React.FC = () => {

  return (
    <>
      <PageTitleWrapper>
        <PageTitle
          heading="Guida ai bioritmi"
          subHeading="Comprendere i bioritmi"
          docs=""
        />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Un Viaggio Verso il Benessere Olistico" />
              <Divider />
              <CardContent>
                <Card>
                  <CardContent>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} xl={8}>
                        <Typography variant="body2" color="text.secondary">
                          I bioritmi sono cicli biologici che influenzano vari aspetti della nostra vita, dalla salute fisica alla stabilità emotiva e alla lucidità mentale.<br/>
                          Questi cicli, che includono il bioritmo fisico, emotivo e intellettivo, possono aiutarci a comprendere meglio noi stessi e a navigare con successo nel mondo.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} xl={4}>
                        <CardMedia
                          sx={{ height: 140 }}
                          image="/static/images/banners/banner4.jpg"
                          title="Bioritmo"
                        />
                      </Grid>
                    </Grid>
                    </CardContent>
                </Card>
              </CardContent>
              </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Bioritmo Fisico: Il Tuo Barometro di Salute" />
              <Divider />
              <CardContent>
                <Card>
                  <CardContent>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} xl={4}>
                        <CardMedia
                          sx={{ height: 140 }}
                          image="/static/images/banners/fisico.jpg"
                          title="Bioritmo"
                        />
                      </Grid>
                      <Grid item xs={12} xl={8}>
                        <Typography variant="body2" color="text.secondary">
                            Il bioritmo fisico riguarda la coordinazione, la forza e il benessere generale. Quando questo ciclo è in una fase alta, potresti sentirti pieno di energia e vitalità. 
                            Al contrario, durante una fase bassa, potresti sentirti stanco o meno resistente. Ascoltare il tuo bioritmo fisico può aiutarti a capire quando è il momento di spingere i tuoi limiti e quando è il momento di riposare e recuperare.                        
                        </Typography>
                      </Grid>
                    </Grid>
                    </CardContent>
                </Card>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Bioritmo Emotivo: La Tua Bussola Emotiva" />
              <Divider />
              <CardContent>
                <Card>
                  <CardContent>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} xl={8}>
                        <Typography variant="body2" color="text.secondary">
                            Il bioritmo emotivo riguarda la creatività, la sensibilità, l’umore, la percezione e la consapevolezza. Durante una fase alta, potresti sentirti più aperto ed empatico,<br/>
                            mentre durante una fase bassa, potresti sentirti più chiuso o distante. <br/>
                            Comprendere il tuo bioritmo emotivo può aiutarti a navigare nelle sfide emotive e a sfruttare al meglio la tua creatività.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} xl={4}>
                        <CardMedia
                          sx={{ height: 140 }}
                          image="/static/images/banners/emotivo.jpg"
                          title="Bioritmo"
                        />
                      </Grid>
                    </Grid>
                    </CardContent>
                </Card>
              </CardContent>
              </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Bioritmo Intellettivo: Il Tuo Faro di Lucidità" />
              <Divider />
              <CardContent>
                <Card>
                  <CardContent>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} xl={4}>
                        <CardMedia
                          sx={{ height: 140 }}
                          image="/static/images/banners/intellettuale.jpg"
                          title="Bioritmo"
                        />
                      </Grid>
                      <Grid item xs={12} xl={8}>
                        <Typography variant="body2" color="text.secondary">
                            Il bioritmo intellettivo riguarda l'intelletto, la memoria, la capacità analitica e la capacità logica. Durante una fase alta, potresti trovare più facile concentrarti e risolvere problemi complessi.<br/>
                            Durante una fase bassa, potresti avere difficoltà a concentrarti o a ricordare le informazioni.<br/>
                            Seguire il tuo bioritmo intellettivo può aiutarti a pianificare attività che richiedono un pensiero critico o una memoria acuta.
                        </Typography>
                      </Grid>
                    </Grid>
                    </CardContent>
                </Card>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Card>
                  <CardContent>
                      <Grid item xs={12} xl={12}>
                        <Typography variant="body2" color="text.secondary">
                          Ricorda, i bioritmi sono solo uno strumento per aiutarti a comprendere meglio te stesso. Non sono una scienza esatta e non dovrebbero mai sostituire l'assistenza medica professionale.<br/>
                          Ma con la consapevolezza e la comprensione, possono essere un potente alleato nel tuo viaggio verso il benessere olistico. <br/><br/>
                          Buon viaggio!
                        </Typography>
                      </Grid>
                    </CardContent>
                </Card>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      {/* <Footer /> */}
    </>
  );
}

export default BioGuide;

