// routes.tsx
import Home from './pages/Home';
import BioCalc from './pages/BioCalc';
import BioInfo from './pages/BioInfo';
import BioGuide from './pages/BioGuide';
import HomeIcon from '@mui/icons-material/Home';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

export const routes = [
  {
    name: 'Home',
    path: '/',
    component: Home,
    icon: () => <HomeIcon />,
  },
  {
    name: 'Calcola il tuo bioritmo',
    path: '/biocalc',
    component: BioCalc,
    icon: () => <ShowChartIcon />,
  },
  {
    name: 'Guida ai bioritmi',
    path: '/bioguide',
    component: BioGuide,
    icon: () => <MenuBookIcon />,
  },
  {
    name: 'Biblioteca dei bioritmi',
    path: '/bioinfo',
    component: BioInfo,
    icon: () => <LibraryBooksIcon />,
  },
];
