// Sidebar.tsx
import React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { routes } from '../../routes';

const Sidebar: React.FC<{ open: boolean, onClose: () => void }> = ({ open, onClose }) => {

  const theme = useTheme();

  return (
    <Drawer 
      variant="persistent" 
      open={open}      
      sx={{
        '& .MuiDrawer-paper': {
          width: '250px',
          height: 'calc(100vh - 80px)',
          marginTop: '80px',
          color: theme.sidebar.textColor,
          background: theme.sidebar.background,
          boxShadow: theme.sidebar.boxShadow,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        },          
      }}
    >
      <div>
        <List>
          {routes.map((route) => (
            <ListItem key={route.name} component={Link} to={route.path} onClick={onClose}>
              <ListItemIcon style={{ minWidth: '35px' }} sx={{color: theme.sidebar.menuItemIconColor}}>{route.icon()}</ListItemIcon>
              <ListItemText primary={route.name} sx={{color: theme.sidebar.textColor}}/>
            </ListItem>
          ))}        
        </List>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <a href="https://www.iubenda.com/privacy-policy/12380573" title="Privacy Policy " target='_blank' style={{ color: theme.sidebar.textColor, textDecoration: 'none' }}>Privacy Policy</a>
        <a href="https://www.iubenda.com/privacy-policy/12380573/cookie-policy" title="Privacy Policy " target='_blank' style={{ color: theme.sidebar.textColor, textDecoration: 'none' }}>Cookie Policy</a>
      </div>
    </Drawer>
  );
}

export default Sidebar;
