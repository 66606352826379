// App.tsx
import React, { useContext, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { styled } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeContext } from './themes/themecontext';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Helmet } from 'react-helmet';

import { routes } from './routes';

import Topbar from './components/Topbar';
import Sidebar from './components/Sidebar';
import Status404 from './pages/Status404';

const App: React.FC = () => {
  
  const themeContext = useContext(ThemeContext);
  
  if (!themeContext) {
    throw new Error("ThemeContext is undefined. Make sure you're rendering App inside a ThemeProvider.");
  }

  const { theme } = themeContext;  

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [sidebarOpen, setSidebarOpen] = useState(!isMobile);

  const MyContainer = styled(Container)({
    padding: '0px !important', 
    maxWidth: '100%',
  });

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };  
  
  return (
    <MuiThemeProvider theme={theme}>
      <Helmet>
        <title>Bioritmo monitor - biomonitor.it</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link href="https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,400&display=swap" rel="stylesheet" />
      </Helmet>      
      <CssBaseline />
      <MyContainer maxWidth={false}>
        <Topbar toggleSidebar={toggleSidebar} />          
        {sidebarOpen && (
            <Sidebar open={sidebarOpen} onClose={() => isMobile && setSidebarOpen(false)} />
        )}
        <main style={{ marginTop:'90px', marginLeft: sidebarOpen ? isMobile ? '10px' : '260px' : '10px'}}>
          <Routes>
            {routes.map((route) => (
              <Route key={route.path} path={route.path} element={<route.component />} />
            ))}
            <Route path="*" element={<Status404 />} />
          </Routes>
        </main>
      </MyContainer>
    </MuiThemeProvider>            
  );
}

export default App;
