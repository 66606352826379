import {
    Box,
    Typography,
    Container,
  } from '@mui/material';
  import { useTheme } from '@mui/material/styles';
  import { styled } from '@mui/material';
  
  
const Status404 = () => {

    const theme = useTheme();
  
    const MainContent = styled(Box)(
      ({ theme }) => `
        height: 100%;
        display: flex;
        flex: 1;
        overflow: auto;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    `
    );
        
    return (
        <MainContent>
          <Container maxWidth="md">
            <Box textAlign="center" style={{marginTop: '100px'}}>
              <img alt="404" height={180} src="/static/images/status/404.svg" />
              <Typography variant="h2" sx={{ my: 2 }}>
                Ci spiace, la pagina che stai cercando non esiste!
              </Typography>
            </Box>
          </Container>
        </MainContent>
    );
  };
  
  export default Status404;

