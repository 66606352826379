// components/DateText/index.tsx

import React, { useEffect } from 'react';

import DatePicker from '@mui/lab/DatePicker';
import { TextField } from '@mui/material';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import dayjs from 'dayjs';

interface DateTextProps {
  selectedDate: Date | null;
  handleDateChangeAndSave: (newDate: dayjs.Dayjs | null) => void;
}

const DateText: React.FC<DateTextProps> = ({ selectedDate, handleDateChangeAndSave }) => {
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="Data di nascita"
        value={selectedDate}
        onChange={handleDateChangeAndSave}
        renderInput={(params) => <TextField {...params} />}
        inputFormat="DD/MM/YYYY"
      />
    </LocalizationProvider>
  );
};

export default DateText;
