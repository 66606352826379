// Home.tsx

import * as React from 'react';
import { Grid, Box } from '@mui/material';
import dayjs from 'dayjs';
import DateText from '../../components/DateText';
import BiorhythmChart from '../../components/BiorhythmChart';
import useBiorhythms from '../../hooks/useBiorhythms'; // Assicurati di importare useBiorhythms
import { useTheme } from '@mui/material/styles';

const BioCalc: React.FC = () => {
  
  const theme = useTheme();
  
  const [selectedDate, handleDateChange] = React.useState<Date | null>(() => {
    const date = localStorage.getItem('date');
    return date ? new Date(date) : null;    
  });

  const biorhythmData = useBiorhythms(selectedDate);

  const handleDateChangeAndSave = async (newDate: dayjs.Dayjs | null) => { 
    if (newDate && newDate.isValid()) {
      handleDateChange(newDate.toDate());
      localStorage.setItem('date', newDate.toDate().toISOString());
    } else {
      const today = dayjs();
      handleDateChange(today.toDate());
      localStorage.setItem('date', today.toDate().toISOString());
    }
  };

  return (
    <>      
      {/* <Box>
        <picture>
          <source media="(max-width: 599px)" srcSet="/static/images/banners/banner-mobile.jpg" />
          <source media="(min-width: 600px)" srcSet="/static/images/banners/banner-desktop.jpg" />
          <img src="/static/images/banners/banner-desktop.png" alt="Monitoraggio Bioritmi" style={{ width: 'calc(100% + 10px)', height: '180px', marginLeft: '-10px' }} />
        </picture>        
      </Box> */}

      <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>

        <Grid item xs={12} md={6}>
          <Box sx={{ marginTop: '0px', display: 'flex', justifyContent: 'center' }}>
            <h1>Calcola il tuo bioritmo</h1>
          </Box>
          <Box sx={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
            <DateText selectedDate={selectedDate} handleDateChangeAndSave={handleDateChangeAndSave} />
          </Box>
        </Grid>

        {biorhythmData ? (
        <Grid item xs={12} md={6}>
          <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3} >

            <Grid item xs={12} style={{marginRight: '10px'}}>
              <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3} >
                <Grid item xs={2}>
                  <Grid container justifyContent="flex-end">
                    <img src="/static/images/icon/fisico.jpg" alt="Stato fisico"  style={{width: '80%'}}/>
                  </Grid>
                </Grid>
                <Grid item xs={10}>
                <span style={{color: theme.biochart.PhysicalColor}}>FISICO</span> (<span style={{color: theme.palette.success.dark}}>{biorhythmData?.PhysicalValue}</span>)<br/>
                  {biorhythmData?.PhysicalDesc}   
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} style={{marginRight: '10px'}}>
              <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
                <Grid item xs={2}>
                  <Grid container justifyContent="flex-end">
                    <img src="/static/images/icon/emozionale.jpg" alt="Stato emozionale"  style={{width: '80%'}}/>
                  </Grid>
                </Grid>
                <Grid item xs={10}>
                <span style={{color: theme.biochart.EmotionalColor}}>EMOZIONALE</span> (<span style={{color: theme.palette.success.dark}}>{biorhythmData?.EmotionalValue}</span>)<br/>
                  {biorhythmData?.EmotionalDesc}
                </Grid>
              </Grid>              
            </Grid>

            <Grid item xs={12} style={{marginRight: '10px'}}>
              <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3} >
                <Grid item xs={2}>
                  <Grid container justifyContent="flex-end">
                    <img src="/static/images/icon/intellettuale.jpg" alt="Stato intellettuale"  style={{width: '80%'}}/>
                  </Grid>
                </Grid>
                <Grid item xs={10}>
                  <span style={{color: theme.biochart.IntellectualColor}}>INTELLETTUALE</span> (<span style={{color: theme.palette.success.dark}}>{biorhythmData?.IntellectualValue}</span>)<br/>
                  {biorhythmData?.IntellectualDesc}
                </Grid>
              </Grid>
            </Grid>

          </Grid>      
        </Grid>
        ) : (
          <Grid item xs={12} md={6} />
        )}

      </Grid>

      <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: 'center', height: '480px' }}>
        {biorhythmData && <BiorhythmChart biorhythmData={biorhythmData} />}
      </Box>
    </>
  );
}

export default BioCalc;
