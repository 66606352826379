// ThemeContext.tsx
import React, { createContext, useState, useEffect } from 'react';
import { lightTheme } from './lightTheme';
import { darkTheme } from './darkTheme';

type ThemeContextType = {
  darkMode: boolean;
  setDarkMode: (mode: boolean) => void;
  theme: typeof lightTheme;
};

// Fornisci un valore predefinito al tuo ThemeContext
const defaultContext: ThemeContextType = {
  darkMode: false,
  setDarkMode: () => {},
  theme: lightTheme,
};

export const ThemeContext = createContext<ThemeContextType>(defaultContext);

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isDarkMode = localStorage.getItem('darkMode') === 'true';
  const [darkMode, setDarkMode] = useState(isDarkMode);
  const theme = darkMode ? darkTheme : lightTheme;

  useEffect(() => {
    localStorage.setItem('darkMode', darkMode.toString());
  }, [darkMode]);

  return (
    <ThemeContext.Provider value={{ darkMode, setDarkMode, theme }}>
      {children}
    </ThemeContext.Provider>
  );
};


