import React from 'react';


import {
  Box,
  Grid,
  Button,
  Container, 
  useMediaQuery
} from '@mui/material';

import Typography from '@mui/material/Typography';

import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material';

const Home: React.FC = () => {
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const MyGridItem = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  }));

  return (
    <>
      <Container>
        <Typography variant={isMobile ? "h4" : "h1"} align="center">
          Benvenuto su Biomonitor.it
        </Typography>
        <Typography variant={isMobile ? "h6" : "h5"} align="center">
          Il tuo strumento personale per monitorare e comprendere i tuoi bioritmi
        </Typography>
        
        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3} style={{marginTop: '10px'}} >
          <Grid item xs={12} md={8}>
            <Typography variant="body1">
              <h2>Interpretazione divertente valori dei bioritmi</h2>
              Nel nostro calcolatore di bioritmi, oltre ai valori numerici, forniamo anche un valore “divertente” per ciascuno dei tre stati: fisico, emotivo e intellettuale. Questi valori divertenti sono pensati per dare un'interpretazione più leggera e accessibile dei tuoi bioritmi. 
              Ecco come interpretarli:
              
              <h3>Stato fisico</h3>
              Il valore divertente dello stato fisico può variare da “Hai lenergia di un supereroe!” a “Oggi potresti sentirti un po' pigro”. Questo valore è pensato per darti un'idea di come potresti sentirti fisicamente in base al tuo bioritmo.

              <h3>Stato emotivo</h3>
              Il valore divertente dello stato emotivo può variare da “Sei al settimo cielo!” a “Potresti avere bisogno di un abbraccio”. Questo valore è pensato per darti un'idea di come potresti sentirti emotivamente in base al tuo bioritmo.

              <h3>Stato intellettuale</h3>
              Il valore divertente dello stato intellettuale può variare da “Sei un genio in erba!” a “Oggi potrebbe non essere il giorno migliore per risolvere enigmi”. Questo valore è pensato per darti un'idea di come potrebbe essere la tua acuità mentale in base al tuo bioritmo.

              <h3>Come leggere i dati</h3>
              Ricorda, questi valori "scherzosi" sono solo per divertimento e non dovrebbero essere presi troppo sul serio. I bioritmi possono offrire una prospettiva interessante.<br/>
              Buon divertimento con i tuoi bioritmi!
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} justifyContent="center" alignItems="stretch">
            <img src="/static/images/banners/calcolo.jpg" alt="Calcolo dei bioritmi"  style={{width: '100%'}}/>
            <Box display="flex" justifyContent="center" style={{marginTop: '5px'}}>
              <Button variant="contained" color="primary" href="/biocalc">
                Calcola il tuo bioritmo online
              </Button>
            </Box>                   
          </Grid>
        </Grid>      

        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3} style={{marginTop: '10px'}} >
          <MyGridItem  item xs={12} md={4}>
            <img src="/static/images/banners/calcolo2.jpg" alt="Calcolo dei bioritmi"  style={{width: '100%'}}/>
          </MyGridItem>
          <Grid item xs={12} md={8}>
            <Typography variant="body1">

              <h2>Calcolo dei bioritmi</h2>
              Il calcolo dei bioritmi può essere un potente strumento per comprendere meglio il tuo corpo e la tua mente. Ecco come funziona:
              I bioritmi sono basati su tre cicli principali: il ciclo fisico, il ciclo emotivo e il ciclo intellettuale. Ogni ciclo ha una durata specifica:

              Il ciclo fisico dura 23 giorni e riguarda la tua energia fisica e la tua resistenza.
              Il ciclo emotivo dura 28 giorni e riguarda i tuoi sentimenti e le tue emozioni.
              Il ciclo intellettuale dura 33 giorni e riguarda la tua capacità di pensare e di apprendere.

              <h3>Come calcolare i tuoi bioritmi</h3>
              Per calcolare i tuoi bioritmi, avrai bisogno della tua data di nascita ed il nostro algoritmo di calcolo dei bioritmi farà il resto!
            </Typography>
          </Grid>
        </Grid>      

        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3} style={{marginTop: '10px'}} >
          <Grid item xs={12} md={8}>
            <Typography variant="body1">
              <h2>La storia dei bioritmi</h2>
              I bioritmi sono un concetto affascinante che ha radici nella storia antica e continua a influenzare la nostra comprensione del corpo umano e della sua interazione con il mondo naturale.

              <h2>Origini antiche</h2>
              L'idea dei bioritmi risale all'antica Grecia, dove i filosofi credevano in un legame tra i ritmi del corpo umano e i cicli cosmici. Ippocrate, spesso considerato il padre della medicina, sosteneva che “l’uomo è un microcosmo”, un piccolo universo la cui struttura riflette quella del cosmo.

              Il Concetto Moderno dei Bioritmi
              Il concetto moderno dei bioritmi fu introdotto nel 20° secolo dal Dr. Wilhelm Fliess, un medico tedesco. Fliess credeva che esistessero due cicli fondamentali nel corpo umano: un ciclo di 23 giorni, che chiamò “maschile”, e un ciclo di 28 giorni, che chiamò “femminile”. Questi cicli, secondo Fliess, influenzavano vari aspetti della salute e del comportamento umano.

              <h2>I bioritmi oggi</h2>
              Oggi, il concetto dei bioritmi è stato ampliato per includere tre cicli principali: il ciclo fisico di 23 giorni, il ciclo emotivo di 28 giorni e il ciclo intellettuale di 33 giorni. Molti credono che comprendere e monitorare questi cicli possa aiutare le persone a vivere vite più sane e produttive.

              Sebbene i bioritmi siano un concetto antico, continuano a offrire una prospettiva unica sulla salute e il benessere umano. Monitorare i propri bioritmi può fornire un prezioso strumento per comprendere meglio se stessi e il proprio posto nel mondo naturale.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <img src="/static/images/banners/storia.jpg" alt="Storia dei bioritmi"  style={{width: '100%'}}/>
          </Grid>
        </Grid>      

      </Container>

      {/* <Footer /> */}
    </>
  );
}

export default Home;

