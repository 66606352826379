import React from 'react';

import PageTitle from 'src/components/PageTitle';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider
} from '@mui/material';

import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

const BioInfo: React.FC = () => {

  return (
    <>
      <PageTitleWrapper>
        <PageTitle
          heading="Biblioteca dei bioritmi"
          subHeading="Raccolta di informazioni sui bioritmi"
          docs="https://it.wikipedia.org/wiki/Bioritmo"
        />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Fonte Wikipedia" />
              <Divider />
              <CardContent>
                <Card>
                  <CardContent>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} xl={8}>
                        <Typography variant="body2" color="text.secondary">
                            Un bioritmo (dal greco βίος - bios, vita e ῥυθμός - rhutmos, ritmo) è un tentativo di predire vari aspetti della vita di una persona attraverso una semplificazione matematica.<br/> 
                            La maggior parte degli scienziati crede che quest'idea non abbia un'efficacia maggiore del caso e considerano il concetto un esempio di pseudoscienza: “La teoria dei bioritmi è una teoria pseudoscientifica che afferma che le nostre vite quotidiane siano significativamente soggette a cicli ritmici ignorati dagli scienziati che studiano i ritmi biologici”.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} xl={4}>
                        <CardMedia
                          sx={{ height: 140 }}
                          image="/static/images/banners/banner2.jpg"
                          title="Bioritmo"
                        />
                      </Grid>
                    </Grid>
                    </CardContent>
                </Card>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Cosa ne pensa Copilot ?" />
              <Divider />
              <CardContent>
                <Card>
                  <CardContent>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} xl={4}>
                        <CardMedia
                          sx={{ height: 140 }}
                          image="/static/images/banners/banner3.jpg"
                          title="Bioritmo"
                        />
                      </Grid>
                      <Grid item xs={12} xl={8}>
                        <Typography variant="body2" color="text.secondary">
                              I bioritmi sono una teoria che sostiene che la vita di una persona sia influenzata da cicli biologici ritmici che riguardano il fisico, l'emozionale, l'intellettuale e l'intuito. Questi cicli iniziano alla nascita e oscillano in modo sinusoidale, determinando il livello delle abilità di una persona in questi ambiti, giorno per giorno. I bioritmi si possono calcolare con delle formule matematiche basate sulla data di nascita e sulla data di riferimento.<br/>
                              Tuttavia, la maggior parte degli scienziati ritiene che questa teoria non abbia alcuna validità scientifica e che sia un esempio di pseudoscienza. Non esistono prove sperimentali che dimostrino una correlazione tra i bioritmi e il comportamento o le prestazioni umane. Inoltre, i bioritmi non tengono conto di altri fattori che possono influire sulla vita di una persona, come l'ambiente, la genetica, la personalità, la salute, lo stress, ecc.<br/>
                              Quindi, la mia opinione è che i bioritmi siano una curiosità matematica, ma non un modo affidabile per prevedere o migliorare la propria vita. Penso che sia più utile seguire uno stile di vita sano ed equilibrato, che rispetti i ritmi naturali del corpo e della mente, senza basarsi su calcoli arbitrari. 😊
                        </Typography>
                      </Grid>
                    </Grid>
                    </CardContent>
                </Card>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Fonte greenMe" />
              <Divider />
              <CardContent>
                <Card>
                  <CardContent>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} xl={8}>
                        <Typography variant="body2" color="text.secondary">
                          Così come la natura, anche gli esseri umani sono soggetti all'alternanza di alcuni periodi che ritornano ciclicamente, pensate ad esempio alle fasi lunari, al cambio delle stagioni, alla notte e al giorno, ecc. La teoria dei bioritmi ritiene che vi siano alcune variazioni e cicli in grado di influenzare la nostra vita a partire dalla nascita.
                          <br/>
                          La teoria dei bioritmi umani si basa sul concetto di ciclo di vita applicato agli esseri umani e non è tanto strano pensare a una sua possibile credibilità se si considera che nel nostro corpo avvengono una serie di fenomeni appunto ciclici: respirazione, frequenza cardiaca, sonno-veglia, periodo mestruale, ecc.
                          <br/>
                          Specifichiamo che si tratta di una teoria pseudoscientifica anche se si basa su calcoli matematici. Secondo i sostenitori dell'esistenza dei bioritmi, i cicli iniziano al momento della nascita per poi oscillare in modo costante nel corso della vita nelle cosiddette onde sinusoidali.                        
                        </Typography>
                      </Grid>
                      <Grid item xs={12} xl={4}>
                        <CardMedia
                          sx={{ height: 140 }}
                          image="/static/images/banners/banner1.jpg"
                          title="Bioritmo"
                        />
                      </Grid>
                    </Grid>
                    </CardContent>
                </Card>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      {/* <Footer /> */}
    </>
  );
}

export default BioInfo;

