// components/BiorhythmChart/index.tsx

import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import {CategoryScale} from 'chart.js';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation'; // Importa il plugin di annotazione

import { BiorhythmData } from '../../interfaces/BiorhythmData';

Chart.register(CategoryScale, annotationPlugin) // Registra il plugin di annotazione

interface BiorhythmChartProps {
  biorhythmData: BiorhythmData;
}

const BiorhythmChart: React.FC<BiorhythmChartProps> = ({ biorhythmData }) => {
    
  const theme = useTheme();
    
    const data = {
        labels: biorhythmData.Biorhythms.map(b => new Date(b.Date)),
        datasets: [
          {
            label: 'Fisico',
            data: biorhythmData.Biorhythms.map(b => b.Physical),
            fill: false,
            backgroundColor: theme.biochart.PhysicalColor,
            borderColor: 'rgba(255, 0, 0, 0.2)',
          },
          {
            label: 'Emozionale',
            data: biorhythmData.Biorhythms.map(b => b.Emotional),
            fill: false,
            backgroundColor: theme.biochart.EmotionalColor,
            borderColor: 'rgba(0, 255, 0, 0.2)',
          },
          {
            label: 'Intellettuale',
            data: biorhythmData.Biorhythms.map(b => b.Intellectual),
            fill: false,
            backgroundColor: theme.biochart.IntellectualColor,
            borderColor: 'rgba(54, 162, 235, 0.2)',
          },
        ],
    };
      
    return biorhythmData.Biorhythms.length > 0 ? 
      <Line 
        data={data} 
        options={{ 
          maintainAspectRatio: false, 
          scales: { 
            x: { 
              type: 'time', 
              time: { unit: 'day'},
              ticks: {
                source: 'labels'
              },
            }
          },
          plugins: {
            annotation: {
              annotations: {
                line1: {
                  type: 'line',
                  scaleID: 'x',
                  value: new Date().toISOString().split('T')[0], // Usa una stringa ISO senza fuso orario
                  borderColor: theme.sidebar.textColor,
                  borderWidth: 2,
                  display: true,
                },
              },
            },
          },
        }} 
      /> : null;
};

export default BiorhythmChart;
