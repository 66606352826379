// TopBar.tsx
import React, { useContext } from 'react';
import { styled } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { ThemeContext } from '../../themes/themecontext';
import Brightness4Icon from '@mui/icons-material/Brightness4'; // Icona per il tema scuro
import Brightness7Icon from '@mui/icons-material/Brightness7'; // Icona per il tema chiaro

const Topbar: React.FC<{ toggleSidebar: () => void }> = ({ toggleSidebar }) => {
  const { darkMode, setDarkMode } = useContext(ThemeContext);

  const handleThemeChange = () => {
    setDarkMode(!darkMode);
  };
  
  const Bar = styled('header')(({ theme }) => ({
    position: 'fixed',
    backgroundColor: theme.palette.background.default,
    height: 80,
    width: '100%',
    padding: '0rem',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.sidebar.dividerBg}`,
    boxShadow: '0px 3px 3px 0px rgba(0, 0, 0, .1)',
    zIndex: 999
  }));
  
  return (
    <>
      <Bar>
        <div style={{ display: 'flex', alignItems: 'center' }}> {/* Aggiungi questo div */}
          <IconButton onClick={toggleSidebar}>
            <MenuIcon />
          </IconButton>
          <img src={darkMode ? '/static/images/logo/LogoBlu.png' : '/static/images/logo/LogoBlu.png'} alt="Logo" style={{ height: '50px', marginLeft: '10px' }} /> {/* Aggiungi un margine a sinistra */}
        </div>
        <IconButton onClick={handleThemeChange}>
          {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>                
      </Bar>
    </>
  );
}

export default Topbar;
