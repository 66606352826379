//useBiorhythms.ts

import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { BiorhythmData } from '../interfaces/BiorhythmData';

const useBiorhythms = (date: Date | null) => {
  
  const [biorhythms, setBiorhythms] = useState<BiorhythmData>();
  const lastDateRef = useRef<Date | null>(null);

  useEffect(() => {
    const fetchBiorhythms = async () => {
      if (date && date !== lastDateRef.current) {
        lastDateRef.current = date;
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/Biorhythm/CalculateBiorhythm`, {
            params: { birthDate: date }
          });
          setBiorhythms(response.data);
        } catch (error) {
          console.error(error);
        }
      }
    };
    fetchBiorhythms();
  }, [date]);

  return biorhythms;
};

export default useBiorhythms;
